@import './styles/seo-page.scss';
@import './styles/chat.scss';

.custom-modal {
  .custom-modal-content {
    min-height: 240px;
    max-width: 530px;
    min-width: 530px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    @media screen and (max-width: 767.98px) {
      min-height: 100%;
      min-width: 100%;
    }
    .page-sub-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
      color: #103996;
      margin: 0;
      text-align: center;
      @media screen and (max-width: 767.98px) {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .page-contents {
      display: flex;
      flex-direction: column;
      // gap: 15px;
      .content {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        &.message {
          flex-direction: column;
          align-items: flex-start;
          .img-div {
            width: 100%;
            margin: 10px 0;
            display: flex;
            align-items: center;
            // justify-content: center;
            img {
              width: 200px;
              height: 200px;
            }
          }
          .message-content {
            color: #2065d1;
          }
        }
        p {
          margin: 0;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          color: #103996;
          @media screen and (max-width: 767.98px) {
            font-size: 14px;
            line-height: 14px;
          }
          &.message-title {
            font-weight: 900;
          }
          &.message-content {
            margin-top: 10px;
          }
        }
      }
    }
    .actions {
      margin: 20px 0;
      display: flex;
      gap: 20px;
      .custom-modal-btn {
        background: #2065d1;
        border-radius: 4px;
        padding: 12px;
        width: 100%;
        max-width: 100%;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #ffffff;
        transition: 0.25s;
        border: 1px solid transparent;
        &:hover {
          border: 1px solid #103996;
          color: #103996;
          background: #fff;
        }

        &.btn-cancel {
          cursor: pointer;
          background: #fff;
          color: #103996;
          border: 1px solid #103996;
          &:hover {
            border: 1px solid #103996;
            color: #fff;
            background: #103996;
          }
        }
      }
    }
  }
}

.MuiDataGrid-virtualScroller + div {
  display: none;
}

.drawer-custom {
  .MuiDrawer-paper {
    width: 280px;
  }

  &.collapsed {
    .MuiAvatar-circular {
      width: 20px;
      height: 20px;
    }

    .MuiDrawer-paper {
      width: 80px;
    }

    .MuiListItemText-root {
      display: none;
    }

    .account {
      padding: 10px;
      div:nth-child(2) {
        display: none;
      }
    }
  }
}
